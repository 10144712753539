import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import Header from "../Components/Header";
import "../css/terms.css";
import axios from "axios";

const GST = () => {
  const [data, setData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  // Fetch website settings
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}settings/data`);
      const data = await response.json();
      setWebsiteSettings(data);
    } catch (error) {
      console.error("Error fetching website settings:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="leftContainer">
        <Header />
        <div className="mt-5 py-4 px-3">
          <div className="m-3">
            <h1>GST Policy</h1>
            <p>
              From 1st October 2023, a new 28% Government Tax (GST) is applicable on the deposits.
            </p>
            <p>
              Let's understand the new GST regime.. If a player deposits Rs.100 to play a game, there will be an inclusive 28% GST levied on the deposit amount, and the user will need to complete a transaction of Rs.100 (Rs. 78.13 + 28% of Rs. 78.13). Thus, Rs. 100 will be settled in the user’s deposit wallet, and Rs. 21.88 will be accounted for as GST paid. The exact GST amount will be credited to the user's bonus wallet. The details of GST paid by the user can be viewed in the View Transactions section on the application.
            </p>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default GST;
