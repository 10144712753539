import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [viewbox, setviewbox] = useState(false);
  const [verified, setverified] = useState(false);

  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const history = useHistory();
  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.Name);
        setCode(res.data.referral);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [Name, setName] = useState();
  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      // // console.log(access_token)
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      // console.log(formData)
      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }

    // await axios.patch(baseUrl+`challange/result/${path}`,
    //   {
    //     status: status
    //   },
    //   { headers })
    //   .then((res) => {
    //     // getPost(res.data)
    //     // console.log(res.data);
    //     history.push("/landing")
    // }).catch((e) => {
    //     // console.log(e);
    //   })
  };
  //avatar
 const cardStyle = {
    width: '300px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
    margin: '20px auto',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid #ddd',
  };

  const infoRowStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
  };

  const iconStyle = {
    width: '30px',
    height: '30px',
    marginRight: '10px',
  };

  const labelStyle = {
    fontWeight: 'bold',
    marginRight: '5px',
  };

  const valueStyle = {
    color: '#333',
  };
  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
      <Header user={profile} />
      <div className="leftContainer" style={{ minHeight: "100vh" }}>
        <div
          className="mt-5 py-4 px-3"
          style={{ background: "rgb(250, 250, 250)" }}
        >
          <div className={`${css.center_xy} py-5`}>
            <label>
              <picture>
                {/* {image} */}
                {console.log(process.env.PUBLIC_URL)}
                {profile && profile.avatar ? (
                  <img
                    height="80px"
                    width="80px"
                    src={baseUrl + `${profile && profile.avatar}`}
                    alt=""
                    style={{ borderRadius: "50px" }}
                  />
                ) : (
                  <img
                    height="80px"
                    width="80px"
                    src={process.env.PUBLIC_URL + `/user.png`}
                    alt=""
                    style={{ borderRadius: "50px" }}
                  />
                )}
              </picture>
            </label>
            <span className={`${css.battle_input_header} mr-1`}>
              {profile && profile.Phone}
            </span>
            <div
              className={`text-bold my-3 ${portcss} font-weight-bold `}
              style={{ fontSize: "1em" }}
            >
              {profile && profile.Name}
              <img
                className={`ml-2`}
                width="20px"
                src={process.env.PUBLIC_URL + "/Images/icon-edit.jpg"}
                alt=""
                onClick={() => {
                  setPortcss(css.inactive_tab);
                  setPortcss1(css.active_tab);
                }}
              />
            </div>
            <div className={`text-bold my-3 ${portcss1}`}>
              <div
                className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                style={{ verticalAlign: "bottom" }}
              >
                <div
                  className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                >
                  <input
                    aria-invalid="false"
                    type="text"
                    maxLength={"20"}
                    className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                    placeholder="Enter Username"
                    value={Name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <img
                className="ml-2"
                width="20px"
                src={
                  process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"
                }
                alt=""
                onClick={() => {
                  setPortcss(css.active_tab);
                  setPortcss1(css.inactive_tab);
                  UpdateProfile(Id);
                }}
              />
            </div>
          </div>
        </div>
        <div className={css.divider_x}></div>

        <div className="p-3" style={{ background: "white" }}>
          <div className={css.text_bold}>Complete Profile</div>
          <div className="">
            <div>
              <div>
                <div className="" style={{}}>
                  {(profile && profile.verified === `unverified` && (
                    <Link
                      className={`d-flex align-items-center ${css.profile_wallet} bg-light mx-1 my-2 py-2`}
                      style={{ backgroundColor: "whitesmoke" }}
                      to={
                        profile && profile.verified === `unverified`
                          ? `/Kyc2`
                          : `/Profile`
                      }
                    >
                      <picture className="ml-4">
                        <img
                          width="32px"
                          src={
                            process.env.PUBLIC_URL + "/Images/kyc-icon-new.png"
                          }
                          alt=""
                          className=""
                        />
                      </picture>
                      <div className={`ml-5 ${css.mytext} text-muted`}>
                        {profile && profile.verified === `unverified`
                          ? "Complete KYC"
                          : profile && profile.verified === "pending"
                          ? "In Process"
                          : profile && profile.verified === "verified"
                          ? "Completed Kyc ✅"
                          : "Completed Kyc ✅"}
                      </div>
                    </Link>
                  )) ||
                    (profile && profile.verified === `verified` && (
                      <>
                        <button
                          className={`d-flex align-items-center ${css.profile_wallet} bg-light mx-1 my-2 py-2`}
                          style={{ backgroundColor: "whitesmoke" }}
                          onClick={() => {
                            setviewbox(true);
                          }}
                        >
                          <picture className="ml-4">
                            <img
                              width="32px"
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/kyc-icon-new.png"
                              }
                              alt=""
                              className=""
                            />
                          </picture>
                          <div className={`ml-5 ${css.mytext} text-muted`}>
                            {profile && profile.verified === `unverified`
                              ? "Complete KYC"
                              : profile && profile.verified === "pending"
                              ? "In Process"
                              : profile && profile.verified === "verified"
                              ? "View Kyc"
                              : "Completed Kyc ✅"}
                          </div>
                        </button>
                        {viewbox && (
<div style={cardStyle}>
  <div style={infoRowStyle}>
    <img src="/Images/identity-card.png" alt="Aadhar Icon" style={iconStyle} />
    <div>
      <span style={labelStyle}>Aadhar No:</span>
      <span style={valueStyle}>{profile?.aadhardata?.[0]?.maskedNumber ?? ""}</span>
    </div>
  </div>

  <div style={infoRowStyle}>
    <img src="/Images/id-card.png" alt="User Icon" style={iconStyle} />
    <div>
      <span style={labelStyle}>Name:</span>
      <span style={valueStyle}>{profile?.aadhardata?.[0]?.name ?? ""}</span>
    </div>
  </div>

  <div style={infoRowStyle}>
    <img src="/Images/calendar.png" alt="DOB Icon" style={iconStyle} />
    <div>
      <span style={labelStyle}>DOB:</span>
      <span style={valueStyle}>{profile?.aadhardata?.[0]?.dateOfBirth ?? ""}</span>
    </div>
  </div>

  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src="/Images/global-address.png" alt="Address Icon" style={iconStyle} />
    <div>
      <span style={labelStyle}>Address:</span>
      <span style={valueStyle}>
        {`${profile?.aadhardata?.[0]?.address?.vtc ?? ""}, ${profile?.aadhardata?.[0]?.address?.subDistrict ?? ""}, ${profile?.aadhardata?.[0]?.address?.district ?? ""}, ${profile?.aadhardata?.[0]?.address?.state ?? ""}`}
      </span>
    </div>
  </div>
</div>

                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={css.divider_x}>
          <div class="mb-3 shadow card">
            <center>
              <div class="bg-light text-dark card-header">
                <b>Metrics</b>
              </div>
            </center>
            <div class="card-body">
              <div class="g-0 gx-2 mb-2 row">
                <div class="col">
                  <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                    <div
                      class="text-capitalize text-start px-2 card-header"
                      style={{ fontSize: "0.9rem;" }}
                    >
                      <div class="hstack gap-1">
                        <img
                          src="https://ludoplayers.com/static/media/sword.9cc91e4925dc62491c20.webp"
                          width="16px"
                          alt="games played"
                        />
                        <span>games played</span>
                      </div>
                    </div>
                    <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">
                      0
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                    <div
                      class="text-capitalize text-start px-2 card-header"
                      style={{ fontSize: "0.9rem;" }}
                    >
                      <div class="hstack gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="16"
                          height="16"
                          fill="currentColor"
                        >
                          <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                          <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                        </svg>
                        <span>chips won</span>
                      </div>
                    </div>
                    <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">
                      0
                    </div>
                  </div>
                </div>
              </div>
              <div class="g-0 gx-2 row">
                <div class="col">
                  <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                    <div
                      class="text-capitalize text-start px-2 card-header"
                      style={{ fontSize: "0.9rem;" }}
                    >
                      <div class="hstack gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="16"
                          height="16"
                          fill="currentColor"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                        </svg>
                        <span>referral earning</span>
                      </div>
                    </div>
                    <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">
                      0.00
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                    <div
                      class="text-capitalize text-start px-2 card-header"
                      style={{ fontSize: "0.9rem;" }}
                    >
                      <div class="hstack gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="16"
                          height="16"
                          fill="currentColor"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                        </svg>
                        <span>Penalty</span>
                      </div>
                    </div>
                    <div class="fs-5 fw-semibold text-start py-1 px-2 card-body">
                      0.00
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="p-3 snipcss-A1eLC snip-div">
                <Link
                  to="/login"
                  className={`${css.center_xy}  text-uppercase py-4 font-weight-bolder text-muted`}
                  onClick={(e) => logout(e)}
                >
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={css.divider_x}></div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
      {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;
