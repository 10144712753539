import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

const Terms_condition = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="leftContainer">
        <Header />
        <div className="mt-5 py-4 px-3">
          <div className="m-3">
            <h1>Terms &amp; Condition</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terms &amp; Condition
                </li>
              </ol>
            </nav>
            <h4>1. The Terms</h4>
            <div className="accordion-body">
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  These <strong>terms and conditions</strong> of use (“Terms”) along with privacy policy (“Privacy Policy”) forms a legally binding agreement (“Agreement”) between You and us (
                </span>
                <span style={{ color: "#d14841", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  rajasthaniludo.com
                </span>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  ).
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  Hence, We insist that You read these Terms and Privacy Policy and let Us know if You have any questions regarding the same. We will try Our best to answer Your queries.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  <strong>A. USERS’ APPROVAL</strong>
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  1. Users approve of and accept our Agreement by:
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (a) reading all terms and conditions.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (b) reading all rules of this app.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  2. Users may accept this Agreement only if:
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (a) Such User is a natural person, is of the legal age (18 years or older), eligibility and mental capacity to form a binding contract with us.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (b) Such User is not a resident of Tamil Nadu, Andhra Pradesh, Telangana, Assam, Orissa, Sikkim, Nagaland.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (c) Such User is a juristic person, is lawfully existing, and has all the authorizations, permits, and allowances to enter into this Agreement and form a binding contract.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  (d) Such User is not legally barred or restricted from using the App.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  3. You understand that We want You to not use the App if You do not understand, approve of, or accept all the terms specified in this Agreement. Hence, You are requested to read these Terms and Privacy Policy carefully and understand the Agreement before You accept it and agree to be bound by it.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  4. The Agreement shall govern the relationship of each User with us. However, We may also execute separate written agreements with its Users. In case of conflict between terms of such separate written agreement and this Agreement, the terms of the separate written agreement shall prevail.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  <strong>B. PROVISION OF THE APP</strong>
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  1. Section 12 of the Public Gambling Act, 1867 provides that games of mere skill are exempt from the application of the Act. The Supreme Court of India and various High Courts in India have opined that a game of mere skill is a game “in which, although the element of chance necessarily cannot be entirely eliminated, success depends principally upon the superior knowledge, training, attention, experience and adroitness of the player. A game of skill is one in which the element of skill predominates over the element of chance.” No penalty can be imposed upon a person for playing such games of skill.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  2. Users must note that ‘Ludo’ game available for challenge in our platform is ‘Games of Skill’, under Indian law, and that we do not support, endorse, or offer to Users ‘games of chance’ for money. While ‘Games of Skill’ do not have a comprehensive definition, they are those games where the impact of a player’s effort and skill on the outcome of a game is higher than the impact of luck and chance.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  3. In adherence to prevailing laws, the Company prohibits individuals residing in specific Indian states, including Andhra Pradesh, Assam, Nagaland, Odisha, Telangana, Sikkim, Tamil Nadu, and those residing outside India, from participating in the Games. Access to the Platform is banned for users from these states.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  4. The game rules are clearly defined on this platform, and Users are encouraged to read, understand, and follow these rules to be successful in these games.
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  5. The games on our platform are ‘Games of Skills’, such that the outcome/success in the games is directly dependent on the User’s effort, performance, and skill. By choosing how to play, the actions of Users shall have a direct impact on the game.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  6. If a User does not comply with the rules of the contest/game, the company is authorized to take any necessary action against such Users as defined in section D (Penalty).
                </span>
              </p>
              <p style={{ textAlign: "left" }}>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  <strong>C. COMMUNICATION</strong>
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  1. By using this platform, the User provides their consent to communicate with the company and receive such communications in relation to the games offered by the platform and its services.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  2. Communication between the Users and the company may happen electronically or through the various social media pages, and the User consents to receive these communications.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  3. The Company is not responsible for any communication happening between Users and any other third-party platform.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  <strong>D. PENALTY</strong>
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  The company reserves the right to penalize any User if found to be in violation of the contest/game rules.
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  <strong>E. CONTACT US</strong>
                </span>
              </p>
              <p>
                <span style={{ color: "#212529", backgroundColor: "#ffffff", fontSize: "16px" }}>
                  For any queries, please feel free to contact us via the contact information provided on the platform.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Terms_condition;
