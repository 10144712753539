import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import SimpleImageSlider from "react-simple-image-slider";
import Marquee from "react-marquee-line";
export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;

  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  const sliderImages = [
    {
      url: "https://api.tuktukludo.com/" + WebSitesettings.LandingImage1,
    },
    {
      url: "https://api.tuktukludo.com/" + WebSitesettings.LandingImage2,
    },
    {
      url: "https://api.tuktukludo.com/" + WebSitesettings.LandingImage3,
    },
  ];
  //   const listx = [
  //     '💸+91897###548 win ₹100 💸 ',
  //     '💸+91897###548 win ₹100 💸 ',
  //     '💸+91897###548 win ₹100 💸 '

  //   ];
  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("m") === "s") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The transaction was successful!",
      });
    } else if (params.get("m") === "f") {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "The transaction failed. Please try again.",
      });
    }
  }, [location]);

  const gent = () => {
    let numbers = [];
    for (let i = 0; i <= 10; i++) {
      let num1 = getRndInteger(600, 999);
      let num2 = getRndInteger(600, 999);
      let cash = getRndInteger(10, 99999);
      var sd = "💸+91" + num1 + "####" + num2 + " win ₹" + cash + " 💸 ";
      numbers.push(sd);
    }
    return numbers;
  };
  const listx = gent();
  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };
  const roledata = async () => {
    await axios
      .get(baseUrl + `me`)
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };
  const [imageNum, setImageNum] = useState(1);
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);
  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "50px" }}>
          <section className=" " style={{ width: "100%", top: "-10px" }}>
            <div className="footer-divider" />
            <center>
              <div
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  height: "auto",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {WebSitesettings.websitemsg}
              </div>
            </center>
          </section>
          {userAllData && userAllData.verified == "unverified" && (
            <section className="footer">
              <div className="footer-divider" />
              <center>
                <div
                  style={{
                    color: "red",
                    width: "90%",
                    height: "50px",
                    textAlign: "center",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    backgroundColor: "#F8F9FA",
                    borderRadius:"5px"
                  }}
                >
                  <span
                    style={{ flex: 1, textAlign: "left", marginLeft: "10px",fontSize:"12px",fontWeight:"bold" }}
                  >
                    Complete KYC to take Withdrawals
                  </span>
                  <button
                    onClick={()=>{
                        window.location.href="/kyc2";
                    }}
                    style={{
                      backgroundColor: "#dc3545",
                      color: "white",
                      border: "none",
                      padding: "10px 10px",
                      cursor: "pointer",
                      marginRight: "10px",
                      borderRadius:"5px",

                    }}
                  >
                    Complete KYC
                  </button>
                </div>
              </center>
            </section>
          )}
          <section className="games-section p-3">
            <div className="align-items-center games-section-title">
             <h5> <b>Our Games</b></h5>
            </div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/classiclite`}
                >
                  <span style={{fontSize:"12px",color:"#000"}} className="d-none blink  d-block text-left">
                    Min ₹50 - Max ₹25k ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={`https://api.rajasthaniludo.com/${WebSitesettings.poster1}`}
                      alt=""
                    />
                  </picture>
                  <div className="goverlay">
                    <div className="text">Live</div>
                  </div>
                </Link>

                <Link
                  className="gameCard-container"
                  to={`/#`}
                >
                  <span   style={{fontSize:"12px",color:"red"}} className="d-none blink  d-block text-right">
                    Coming Soon
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={`https://api.rajasthaniludo.com/${WebSitesettings.poster2}`}
                      alt=""
                    />
                  </picture>
                  <div className="goverlay">
                    <div className="text">Live</div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/Header_profile.jpg"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "56px", height: "56px" }}
                />
              </picture>
              <span
                style={{
                  color: "rgb(149, 149, 149)",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}
                . Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/gst">
                    GST Policy
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            </Collapse>
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="footer-text-bold">About Us</div>
              <br />
              <div className="footer-text">
              
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                real-money gaming product owned and operated by{" "}
                {WebSitesettings ? WebSitesettings.CompanyName : ""} ("
                {WebSitesettings ? WebSitesettings.WebsiteName : ""}" or "We" or
                "Us" or "Our").
              </div>
              <br />
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                skill-based real-money gaming platform accessible only for our
                users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                >
                  {WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                </a>
                . On {WebSitesettings ? WebSitesettings.WebsiteName : ""}, users
                can compete for real cash in Tournaments and Battles. They can
                encash their winnings via popular options such as Paytm Wallet,
                Amazon Pay, Bank Transfer, Mobile Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} has a
                wide-variety of high-quality, premium HTML5 games. Our games are
                especially compressed and optimised to work on low-end devices,
                uncommon browsers, and patchy internet speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends.
                If you have any suggestions around new games that we should add
                or if you are a game developer yourself and want to work with
                us, don't hesitate to drop in a line at{" "}
                <a
                  href={
                    WebSitesettings
                      ? "mailto:" + WebSitesettings.CompanyEmail
                      : ""
                  }
                >
                  {WebSitesettings ? WebSitesettings.CompanyEmail : ""}
                </a>
                !
              </div>
            </div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
    </>
  );
}
